// extracted by mini-css-extract-plugin
export var alignLeft = "B_pn g_gm g_cK g_fy";
export var alignCenter = "B_cS g_gn g_cH g_fz";
export var alignRight = "B_pp g_gp g_cL g_fB";
export var element = "B_tJ g_dw g_dk";
export var customImageWrapper = "B_tK g_dw g_dk g_b5";
export var imageWrapper = "B_rT g_dw g_b5";
export var masonryImageWrapper = "B_p7";
export var gallery = "B_tL g_bD g_cD";
export var width100 = "B_bD";
export var map = "B_tM g_bD g_bP g_b5";
export var quoteWrapper = "B_hH g_cG g_cS g_dw g_dk g_fy";
export var quote = "B_tN g_cG g_cS g_fy";
export var quoteBar = "B_p1 g_bP";
export var quoteText = "B_p2";
export var customRow = "B_qg g_cH g_b5";
export var separatorWrapper = "B_tP g_bD g_cD";
export var articleText = "B_pG g_dw";
export var videoIframeStyle = "B_pX g_f3 g_bD g_bP g_cC g_c4 g_bY";