// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_q5 g_hp g_dw";
export var heroHeaderCenter = "r_hq g_hq g_dw g_fz";
export var heroHeaderRight = "r_hr g_hr g_dw g_fB";
export var heroParagraphLeft = "r_q6 g_hl g_dz";
export var heroParagraphCenter = "r_hm g_hm g_dz g_fz";
export var heroParagraphRight = "r_hn g_hn g_dz g_fB";
export var heroBtnWrapperLeft = "r_q7 g_p g_f0 g_bD g_cD g_cK";
export var heroBtnWrapperCenter = "r_q8 g_f1 g_f0 g_bD g_cD g_cH";
export var heroBtnWrapperRight = "r_q9 g_f2 g_f0 g_bD g_cD g_cL";
export var overlayBtnWrapper = "r_rb g_hk g_b6 g_cf g_cg g_ch g_cr g_dg";
export var design4 = "r_rc g_hj g_b6 g_cf g_cg g_cr";
export var heroExceptionSmall = "r_rd y_rd";
export var heroExceptionNormal = "r_rf y_rf";
export var heroExceptionLarge = "r_rg y_rg";
export var Title1Small = "r_rh y_rh y_sl y_sm";
export var Title1Normal = "r_rj y_rj y_sl y_sn";
export var Title1Large = "r_rk y_rk y_sl y_sp";
export var BodySmall = "r_rl y_rl y_sl y_sF";
export var BodyNormal = "r_rm y_rm y_sl y_sG";
export var BodyLarge = "r_rn y_rn y_sl y_sH";