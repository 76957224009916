// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_tt";
export var lbContainerInner = "z_tv";
export var movingForwards = "z_tw";
export var movingForwardsOther = "z_tx";
export var movingBackwards = "z_ty";
export var movingBackwardsOther = "z_tz";
export var lbImage = "z_tB";
export var lbOtherImage = "z_tC";
export var prevButton = "z_tD";
export var nextButton = "z_tF";
export var closing = "z_tG";
export var appear = "z_tH";