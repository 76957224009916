// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "t_rL g_cH";
export var storyRowWrapper = "t_jn g_jn g_cN";
export var storyLeftWrapper = "t_rM g_cD g_cS";
export var storyWrapperFull = "t_rN g_dH";
export var storyWrapperFullLeft = "t_nL g_dH g_cD g_cS g_cH";
export var contentWrapper = "t_pP g_jp";
export var storyLeftWrapperCenter = "t_rP g_jq g_cD g_cH g_cS g_cM";
export var storyRightWrapperCenter = "t_rQ g_jv";
export var storyHeader = "t_rR g_jt g_bD g_dw";
export var storyHeaderCenter = "t_js g_js g_bD g_dw g_fz g_cC";
export var storyParagraphCenter = "t_jr g_jr g_cC g_fz";
export var storyBtnWrapper = "t_rS g_p g_f0 g_bD g_cD g_cK";
export var storyBtnWrapperCenter = "t_jx g_jx g_f0 g_bD g_cD g_cH";
export var imageWrapper = "t_rT g_gd g_b5";
export var imageWrapperFull = "t_rV g_bD g_bP g_C g_b5";