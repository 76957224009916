// extracted by mini-css-extract-plugin
export var alignDiscLeft = "q_qy g_gm g_cK g_fy";
export var alignLeft = "q_pn g_gm g_cK g_fy";
export var alignDiscCenter = "q_qz g_gn g_cH g_fz";
export var alignCenter = "q_cS g_gn g_cH g_fz";
export var alignDiscRight = "q_qB g_gp g_cL g_fB";
export var alignRight = "q_pp g_gp g_cL g_fB";
export var footerContainer = "q_qC g_fW g_cZ";
export var footerContainerFull = "q_qD g_fT g_cZ";
export var footerHeader = "q_kZ g_kZ";
export var footerTextWrapper = "q_qF g_bD";
export var footerDisclaimerWrapper = "q_k4 g_k4 g_dn";
export var badgeWrapper = "q_qG g_bD g_cD g_cS g_cJ g_cM";
export var footerDisclaimerRight = "q_qH undefined";
export var footerDisclaimerLeft = "q_qJ undefined";
export var verticalTop = "q_qK g_cD g_cM g_cR g_cP";
export var firstWide = "q_qL";
export var disclaimer = "q_qM g_cD g_cM";
export var socialDisclaimer = "q_qN";
export var left = "q_qP";
export var wide = "q_qQ g_dC";
export var right = "q_qR g_cN";
export var line = "q_gh g_gj g_dy";
export var badgeDisclaimer = "q_qS g_cG g_cS g_cM";
export var badgeContainer = "q_qT g_cD g_cL g_cS";
export var badge = "q_qV";
export var padding = "q_qW g_fb";
export var end = "q_qX g_cL";
export var linkWrapper = "q_qY g_fF";
export var link = "q_v g_fF";
export var colWrapper = "q_qZ g_dB";
export var consent = "q_f g_f g_cG g_cS";
export var media = "q_q0 g_cB g_fC";
export var itemRight = "q_q1";
export var itemLeft = "q_q2";
export var itemCenter = "q_q3";
export var exceptionWeight = "q_q4 y_sN";