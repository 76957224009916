// extracted by mini-css-extract-plugin
export var carouselContainer = "H_vL g_bD g_bP g_C g_b5";
export var carouselContainerCards = "H_vM H_vL g_bD g_bP g_C g_b5";
export var carouselContainerSides = "H_vN g_bD g_bP g_b5";
export var prevCarouselItem = "H_vP g_bD g_bP g_b6 g_br";
export var prevCarouselItemL = "H_vQ H_vP g_bD g_bP g_b6 g_br";
export var moveInFromLeft = "H_vR";
export var prevCarouselItemR = "H_vS H_vP g_bD g_bP g_b6 g_br";
export var moveInFromRight = "H_vT";
export var selectedCarouselItem = "H_vV g_bD g_bP g_b5 g_C";
export var selectedCarouselItemL = "H_vW H_vV g_bD g_bP g_b5 g_C";
export var selectedCarouselItemR = "H_vX H_vV g_bD g_bP g_b5 g_C";
export var nextCarouselItem = "H_vY g_bD g_bP g_b6 g_br";
export var nextCarouselItemL = "H_vZ H_vY g_bD g_bP g_b6 g_br";
export var nextCarouselItemR = "H_v0 H_vY g_bD g_bP g_b6 g_br";
export var arrowContainer = "H_v1 g_bP g_b6 g_fF g_cp g_cb g_c4";
export var prevArrowContainer = "H_v2 H_v1 g_bP g_b6 g_fF g_cp g_cb g_c4";
export var prevArrowContainerHidden = "H_v3 H_v2 H_v1 g_bP g_b6 g_fF g_cp g_cb g_c4 g_br";
export var nextArrowContainer = "H_v4 H_v1 g_bP g_b6 g_fF g_cp g_cb g_c4";
export var carouselContainerProducts = "H_v5";
export var nextArrowContainerHidden = "H_v6 H_v4 H_v1 g_bP g_b6 g_fF g_cp g_cb g_c4 g_br";
export var arrow = "H_lp g_b6";
export var prevArrow = "H_v7 H_lp g_b6";
export var nextArrow = "H_v8 H_lp g_b6";
export var carouselIndicatorContainer = "H_v9 g_bD g_fz g_cp g_cD g_cH g_cS g_cM g_b6";
export var btnsContainer = "H_wb g_bD g_cD g_cJ";
export var carouselText = "H_wc g_ft g_cD g_cH g_cS g_c4 g_fF g_dH";
export var carouselTextInactive = "H_wd H_wc g_ft g_cD g_cH g_cS g_c4 g_fF g_dH";
export var indicator = "H_wf g_db";
export var carouselIndicator = "H_wg H_wf g_db";
export var carouselIndicatorSelected = "H_wh H_wf g_db";
export var arrowsContainerTopRight = "H_wj g_b6 g_cp g_cG";
export var arrowsContainerBottomLeft = "H_wk g_b6 g_cp g_cG";
export var arrowsContainerSides = "H_wl g_b6 g_cp g_cG";
export var smallArrowsBase = "H_wm g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainer = "H_wn H_wm g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerPrev = "H_wp H_wn H_wm g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerSmall = "H_wq H_wm g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerPrevSmall = "H_wr H_wq H_wm g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var icon = "H_qf";
export var iconSmall = "H_ws";
export var multipleWrapper = "H_wt g_cG g_cJ g_C";
export var multipleImage = "H_wv g_cG";
export var sidesPrevContainer = "H_ww H_wn H_wm g_cX g_cD g_cH g_cS g_c4 g_fF g_dH g_gl g_b6 g_cp g_dH";
export var sidesNextContainer = "H_wx H_wn H_wm g_cX g_cD g_cH g_cS g_c4 g_fF g_dH g_gl g_b6 g_cp g_dH";